import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
  <h2 style="font-size: 16px;color: #ffffff;text-align: center;font-family:Roboto;font-weight:400;font-style:normal" class="vc_custom_heading">
    COPYRIGHT © 2023 THE I QUADRANT PTE. LTD.
  </h2>
  <h5 style="font-size: 16px;color: #ffffff;text-align: center;font-family:Roboto;font-weight:400;font-style:normal">{{version}}</h5>
  `,
})
export class FooterComponent implements OnInit  {
  version: string = "3.5.2"
  constructor(private userService: UserService) {

  }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.userService.getConfigurationByName("version").subscribe(data =>{
      if(data){
        this.version = data.result["value"];
      }
    }, error => {});
  }
}
